angular.module("app")
    .directive("cArea", function ($rootScope, _mapFieldService, _view, gettextCatalog, _logicService) {
        return {
            restrict: "E",
            template: "{{text}}",
            scope: {
                value: '@value'
            },
            link: function ($scope) {

                $scope.$watch('value', function() {
                    if (_view.getCurrentUser().units == "IMPERIAL") {
                        $scope.x = _logicService.round($scope.value / 4047, 1);
                        $scope.text = $scope.x + " " + gettextCatalog.getString('ac');
                    } else {
                        $scope.x = _logicService.round($scope.value / 10000, 1);
                        $scope.text = $scope.x + " " + gettextCatalog.getString('ha');
                    }
                });
            }
        }
    });